.quick-search-dropdown {
  z-index: 1090 !important;

  @media (max-width: 500px) {
    & {
      width: calc(100% - 2rem) !important;
      inset: 56.5px auto auto 1rem !important;
    }
  }
}

.ant-menu {
  @media (max-width: 991px) {
    & {
      width: calc(100vw - 100px);
      max-height: calc(100vh - 64px);
      overflow: auto;
    }
  }
  @media (max-width: 576px) {
    & {
      width: calc(100vw - 2rem);
    }
  }
}

// Less space between menu items as requested by Jacob
.ant-menu-submenu-popup .ant-menu-vertical >.ant-menu-item {
  height: 32px !important;
  line-height: 32px !important;
}

.ant-menu-item:hover {
  background-color: #460b38 !important;
  a {
    color: white !important;
  }
}

.ant-menu-item > .ant-badge > a {
  color: #460b38 !important;
}

.ant-menu-submenu {
  color: #460b38 !important;
  background-color: #e6eafd !important;
  &:hover {
    background-color: #460b38 !important;
    color: white !important;
  }
}

.ant-menu-sub {
  background-color: #e6eafd !important;
  a {
    color: #460b38 !important;
  }
}

.ant-menu-submenu-inline ul.ant-menu-inline li span a {
  color: #460b38 !important;
}

.ant-menu-submenu-inline ul.ant-menu-inline li:hover span a {
  color: white !important;
}

.new-search-tooltip {
  position: absolute;
  right: 34px;
  top: -12px;
  z-index: 2;
  height: 32px;
}
